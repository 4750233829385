import React from 'react'
import { Helmet } from 'react-helmet'

import { useHistory } from 'react-router-dom'
import Card from '../Card'
import { CardContainer } from './styles'

export default () => {
  const history = useHistory()

  return (
    <div data-testid='okulistyka'>
      <Helmet>
        <title>CM Okomed - Okulistyka</title>
        <meta name='description'
          content='Witamy w Centrum Medycznym "OKOMED".
W naszej placówce działają specjalistyczne praktyki lekarskie,zakład optyczny oraz apteka. Pracujący tu lekarze posiadają wysokie kwalifikacje i wieloletnie doświadczenie zawodowe.
Zapraszamy do skorzystania z naszych usług.' />
      </Helmet>
      <CardContainer>
        <Card
          onClick={() => history.push('/okulistyka/stangret')}
          title='lek. med. Małgorzata Stangret'
        >
          <img src='/images/IMG_1048-large.jpg' />
        </Card>
        <Card
          onClick={() => history.push('/okulistyka/wojtusciszyn')}
          title='lek. med. Henryk Wojtuściszyn'
        >
          <img src='/images/IMG_1069-large.jpg' />
        </Card>
      </CardContainer>
    </div>
  )
}
