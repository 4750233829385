import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Dermatologia from './components/pages/Dermatologia'
import Footer from './components/Footer'
import Home from './components/pages/Home'
import Kontakt from './components/pages/Kontakt'
import NavBar from './components/NavBar'
import Oferta from './components/pages/Oferta'
import Okulistyka from './components/pages/Okulistyka'
import Optyk from './components/pages/Optyk'
import Podologia from './components/pages/Podologia'
import Stangret from './components/pages/Stangret'
import Wojtusciszyn from './components/pages/Wojtusciszyn'
import { Logo, Content } from './styles'

export default () =>
  <Router>
    <Helmet>
      <meta charset='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='theme-color' content='#000000' />
      <title>Centrum Medyczne Okomed S.C. - Lubin, ul. Budowniczych LGOM 61B-C</title>
      <meta name='description' content='Witamy w Centrum Medycznym "OKOMED".
W naszej placówce działają specjalistyczne praktyki lekarskie, zakład optyczny, oraz apteka.
Pracujący tu lekarze posiadają wysokie kwalifikacje i wieloletnie doświadczenie zawodowe.
Zapraszamy do skorzystania z naszych usług.' />
      <meta name='keywords' content='Okomed, Lubin, Okulistyka, Dermatologia, Optyk, Okulistyka Lubin, Dermatologia Lubin, Optyk Lubin' />
    </Helmet>
    <Logo />
    <NavBar />
    <Content>
      <Switch>
        <Route path='/oferta'>
          <Oferta />
        </Route>
        <Route path='/okulistyka/stangret'>
          <Stangret />
        </Route>
        <Route path='/okulistyka/wojtusciszyn'>
          <Wojtusciszyn />
        </Route>
        <Route path='/okulistyka'>
          <Okulistyka />
        </Route>
        <Route path='/dermatologia'>
          <Dermatologia />
        </Route>
        <Route path='/podologia'>
          <Podologia />
        </Route>
        <Route path='/optyk'>
          <Optyk />
        </Route>
        <Route path='/kontakt'>
          <Kontakt />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Content>
    <Footer />
  </Router>
