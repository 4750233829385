import React from 'react'
import { Helmet } from 'react-helmet'
import Banner from '../Banner'
import Card from '../Card'
import { ImageBar, CardContainer, Title, LogoNfz } from './styles'

export default () =>
  <div data-testid='okulistyka-wojtusciszyn'>
    <Helmet>
      <title>Okulistyka - lek. med. Henryk Wojtuściszyn</title>
      <meta name='description' content='Indywidualna Specjalistyczna Praktyka Lekarska
lek. med.Henryk Wojtuściszyn - specjalista chorób oczu.
Gabinet nr 1' />
    </Helmet>
    <Banner
      title='Indywidualna Specjalistyczna Praktyka Lekarska'
      subtitle='lek. med. Henryk Wojtuściszyn - specjalista chorób oczu'
    >
      <ImageBar>
        <img height={131} src='/images/IMG_1069-small.jpg' />
        <img height={131} src='/images/IMG_1072-small.jpg' />
        <img height={131} src='/images/P1070301-small.jpg' />
      </ImageBar>
    </Banner>
    <CardContainer>
      <Card title='Oferta'>
        <ul>
          <li >diagnostyka i leczenie chorób oczu,</li>
          <li >dobór okularów i soczewek kontaktowych,</li>
          <li >diagnostyka i leczenie jaskry,</li>
          <li >badania diagnostyczne:
            <ul>
              <li >badanie pola widzenia,</li>
              <li >pomiar ciśnienia śródgałkowego z uwzględnieniem grubości rogówki,</li>
              <li >tomografia tarczy nerwu II i siatkówki – OCT,</li>
              <li >ocena kąta przesączania&nbsp;– gonioskopia, OCT</li>
            </ul>
          </li>
        </ul>
      </Card>
      <Card title='Kontakt'>
        <ul>
          <li>Gabinet nr1 , I piętro</li>
          <li>ul. Budowniczych LGOM 61B/1</li>
          <li>59-300 Lubin</li>
          <li>rejestracja poniedziałek – piątek 7:30 – 18</li>
          <li>tel: 76 7441892</li>
          <li>kontakt: 601582757</li>
        </ul>
        <i>Posiadamy podpisaną umowę z NFZ</i>
        <LogoNfz />
        <Title>Wizyty prywatne</Title>
        <ul>
          <li>poniedziałek, czwartek 16-17 po wcześniejszej rejestracji telefonicznej</li>
        </ul>
      </Card>
    </CardContainer>
    <CardContainer>
      <Card title='Oferta'>
          Gabinet przy ul. Kamiennej 39, świadczenia tylko komercyjne:
        <ul>
          <li >diagnostyka i leczenie chorób oczu,</li>
          <li >dobór soczewek kontaktowych (miękkie, toryczne, do korekcji stożka rogówki) – 20-letnie doświadczenie,</li>
          <li >komputerowa topografia rogówki</li>
        </ul>
      </Card>
      <Card title='Kontakt'>
        <ul>
          <li>ul. Kamienna 39</li>
          <li>59-300 Lubin</li>
          <li>Godziny przyjęć:
            <ul>
              <li>Środa 16-18, Piątek 18-20</li>
            </ul>
          </li>
          <li>Rejestracja: <ul>
            <li>Tel: 76 8425406,<br />601 582757</li>
          </ul>
          </li>
        </ul>
      </Card>
    </CardContainer>
  </div>
