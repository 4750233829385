import React from "react";
import Banner from "../Banner";
import Card from "../Card";
import {
  AnnouncementParagraph,
  CardContainer,
  HomepageImageBar,
} from "./styles";

export default () => (
  <div data-testid="index">
    <Banner title='Witamy w Centrum Medycznym "Okomed"'>
      <HomepageImageBar>
        <img height={187} src="/images/IMG_1071-small.jpg" />
        <img height={187} src="/images/IMG_6870-small.jpg" />
        <img height={187} src="/images/IMG_6878-small.jpg" />
      </HomepageImageBar>
    </Banner>
    <CardContainer>
      <Card>
        <AnnouncementParagraph>
          <u>OGŁOSZENIE</u>
          <br />
          <br />
          <b>Przychodnia i optyk będą nieczynne w dniu 31.05</b>
        </AnnouncementParagraph>
      </Card>
    </CardContainer>
  </div>
);
