import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Card from '../Card'
import { OfertaCardContainer } from './styles'

export default () => {
  const history = useHistory()

  return (
    <div data-testid='oferta'>
      <Helmet>
        <title>CM Okomed - Oferta</title>
        <meta name='description'
          content='Witamy w Centrum Medycznym "OKOMED".
W naszej placówce działają specjalistyczne praktyki lekarskie,zakład optyczny oraz apteka. Pracujący tu lekarze posiadają wysokie kwalifikacje i wieloletnie doświadczenie zawodowe.
Zapraszamy do skorzystania z naszych usług.' />
      </Helmet>
      <OfertaCardContainer>
        <Card
          onClick={() => history.push('/okulistyka/wojtusciszyn')}
          title='OKULISTA'
        >
          <ul>
            <li>
              Indywidualna Specjalistyczna Praktyka Lekarska

            </li>
          </ul>
          <p className='small withLeftMargin'>
            lek. med.Henryk Wojtuściszyn
            <br />
            specjalista chorób oczu
          </p>
        </Card>
        <Card
          onClick={() => history.push('/okulistyka/stangret')}
          title='OKULISTA'
        >
          <ul>
            <li>
              Indywidualna Specjalistyczna Praktyka Lekarska<br />
            </li>
          </ul>
          <p className='small withLeftMargin'>
            lek. med. Małgorzata Stangret
            <br />
            specjalista chorób oczu
          </p>
        </Card>
        <Card
          onClick={() => history.push('/dermatologia')}
          title='DERMATOLOG'
        >
          <ul>
            <li>
              Prywatny Gabinet Lekarski<br />
            </li>
          </ul>
          <p className='small withLeftMargin'>
            lek. med. Grażyna Mąkosa
            <br />
            specjalista chorób skóry i wenerologii
          </p>
        </Card>
        <Card
          onClick={() => history.push('/optyk')}
          title='OPTYK'
        >
          <ul>
            <li>
              Zakład Optyczny "Twój Optyk" s.j. <br />
            </li>
          </ul>
          <p className='small withLeftMargin'>
            Ewelina Niemczuk
            <br />
            Optyk
          </p>
        </Card>
      </OfertaCardContainer>
    </div>
  )
}
