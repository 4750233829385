import styled from 'styled-components'

export const Container = styled.div`
  box-shadow: 1px 1px 10px 2px #ddd;
  border-radius: 6px;
  color: #404040;
  padding: 1rem;
  flex: 1;
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};

  p {
    font-size: 1rem;
  }
  
  p.small {
    font-size: 0.8125rem;
  }

  p.withLeftMargin {
    margin-left: 2rem;
  }

  ul {
    margin: 0 0 1rem 0.875rem;
    padding: 0;
    list-style-type: none;
  }

  li {
    font-size: 0.8125rem;
    padding-left: 1.25rem;
    margin: 3px 0 0 0;
    line-height: 175%;
  }

  ul>li:before {
    content: url(/images/postbullets.png);
    margin-right: 0.625rem;
    margin-left: -1rem;
    bottom: 2px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
  }

  img {
    max-width: 100%;
  }

  @media (max-width: 768px) {
    li > ul {
      margin-top: 0;
    }
  }
`

export const Title = styled.h3`
  font-size: 1.125rem;
  font-weight: normal;
  margin: 0.625rem 0 1rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin: 0;
  }
`
