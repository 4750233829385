import styled from 'styled-components'

export const Logo = styled.div`
  background: url("/images/header.png") no-repeat;
  background-position: center;
  height: 200px;
  z-index: 10;

  @media (max-width: 768px) {
    background-size: 140vw;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
  box-shadow: 1px 1px 10px 2px #ddd;


  }
`

export const Content = styled.div`
  margin: 1rem auto 0;
  max-width: 1000px;
  min-height: calc(100vh - 200px - 42px - 20px - 50px); /* banner: 200, navbar: 42, footer-margin-top: 20, footer: 50*/

  @media (max-width: 768px) {
    margin-top: 60px;
  }
`
