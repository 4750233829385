import styled from 'styled-components'

export const Container = styled.div`
  background-color: #333;
  border-bottom:1px solid #ddd;
  border-top:1px solid #ddd;
  font-size: 14px;
  line-height: 42px;

  a {
    color: white;
    padding: 1rem;
    text-align:center;
    text-decoration: none;
  }

  a.active,
  a:hover {
    color: #fe6d06;
  }
`

export const Submenu = styled.ul`
  display: none;
  position:absolute;

  @media (max-width: 768px) {
    display: block;
    position:relative;
  }
`

export const SubmenuItem = styled.li`
  background:#fefefe;
  border-bottom: 1px solid #dedede;
  display:flex;
  min-width: 260px;
  padding-left: 1em;
  padding-right: 1em;

  a {
    color: #6a7d90;
    padding: 0;
  }

  @media (max-width: 768px) {
    background: none;
    border: 0;
    padding: 0;
    min-width: unset;

    a {
      color: white;
    }
  }
`

export const Menu = styled.ul`
  align-items: center;
  display: flex;
  flex-direction:row;
  justify-content: center;
  list-style: none;
  padding:0;
  margin:0;
  z-index: 10;

  @media (max-width: 768px) {
    align-items: flex-start;
    background-color: #333;
    flex-flow: column nowrap;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 3.5rem;
    position: fixed;
    right: 0;
    top: 0;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    width: 300px;
  }
`

export const MenuItem = styled.li`
  display:block;
  list-style-type:none;
  position: relative;
  text-transform:uppercase;

  &:before {
    background: url(/images/menuseparator.png) center center no-repeat;
    content: ' ';
    display: block;
    height: 42px;
    left: -1px;
    position: absolute;
    top: 0;
    width: 1px;
  }

  &:first-child:before {
    background: unset;
    content: none;
  }

  @media (min-width: 768px) {  
    &:hover > ${Submenu} {
      display:block;
      padding-left:0;
    }
  }

  @media (max-width: 768px) {    
    &:before {
      background: none;
      content: none;
      display: none;
    }
  }
`

export const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 10px;
  right: 15px;
  z-index: 20;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#ccc' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`
