import React from 'react'
import { string, func, node } from 'prop-types'
import { Container, Title } from './styles'

const Card = ({ children, onClick, title }) =>
  <Container onClick={onClick}>
    <Title>
      {title}
    </Title>
    {children}
  </Container>

Card.propTypes = {
  children: node,
  onClick: func,
  title: string
}
export default Card
