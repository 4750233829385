import styled from 'styled-components'

export const ImageBar = styled.div`
  display: flex;
  margin: 1rem 0;
  
  img {
        border: 3px solid #7a7a7a;
        margin: 7px;
    }
  
    @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`

export const HomepageImageBar = styled(ImageBar)`
  justify-content: space-around;
`

export const CardContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;

  > div + div {
    margin-left: 0.75rem;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;

    > div + div {
      margin-left: 0.5rem;
    }
    
    > div {
      margin: 0 0.5rem 1rem;
    }
  }
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: normal;
  margin: 0.75rem 0 0;
`

export const LogoNfz = styled.div`
  background: url("/images/nfz_logo.jpg") no-repeat center;
  height: 29px;
  width: 75px;
  padding: 1rem 0;
`

export const OfertaCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  
  > div {
    flex: 1 0 45%;
    margin-bottom: 1rem;
  }

  > div:nth-child(even){
    margin-left: 0.5rem;
  }

  @media (max-width: 768px) {
    flex-flow: column nowrap;

    > div {
      flex: 1 0 45%;
      margin: 0 0.5rem 1rem;
    }

    > div:nth-child(even){
    margin-left: 0.5rem;
  }
  }
`

export const KontaktDetails = styled.div`
  font-size: 20px;
  line-height: 28px;
  text-align: center;
`

export const AnnouncementParagraph = styled.p`
  && {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    text-align: center;
  }
`
