import React from 'react'
import { Helmet } from 'react-helmet'
import Banner from '../Banner'
import Card from '../Card'
import { CardContainer, Title } from './styles'

export default () =>
  <div data-testid='podologia'>
    <Helmet>
      <title>Podosfera - gabinet podologiczny</title>
      <meta name='description' content='PODOSFERA. Gabinet podologiczny. Podolog Katarzyna Nawrot. Gabinet nr 4' />
    </Helmet>
    <Banner
      title='Podosfera - gabinet podologiczny'
      subtitle='Podolog Katarzyna Nawrot'
    />
    <CardContainer>
      <Card title='Oferta'>
        <ul>
          <li>Pedicure specjalistyczny</li>
          <li>Terapia wrastających paznokci</li>
          <li>Terapia brodawek wirusowych</li>
          <li>Usuwanie odcisków i modzeli</li>
          <li>Terapia pękających pięt</li>
          <li>Terapia paznokci zmienionych chorobowo</li>
          <li>Pobieranie materiału do badań mykologicznych</li>
          <li>Rekonstrukcja paznokci</li>
          <li>Profilaktyka i pielęgnacja stopy cukrzycowej</li>
        </ul>
      </Card>

      <Card title='Godziny przyjęć'>
      <ul>
          <li>pn. 14-19</li>
          <li>wt.  8-14</li>
          <li>śr. 8-14</li>
          <li>czw. 14-19</li>
          <li>pt. 8-14</li>
        </ul>
        <Title>Kontakt</Title>
        <ul>
          <li>Tel. 783610510</li>
          <li>Gabinet nr 4, II piętro</li>
          <li>ul. Budowniczych LGOM 61B/4</li>
          <li>59-300 Lubin</li>
        </ul>
      </Card>
      
    </CardContainer>
  </div>
