import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background-color: #333;
  border-bottom:1px solid #ddd;
  border-top:1px solid #ddd;
  color: white;
  display: flex;
  font-size: 10px;
  height: 50px;
  justify-content: flex-end;
  padding: 0 1rem;
  text-align: right;
`
