import styled from 'styled-components'

export const Container = styled.div`
    align-items: center;
    background-color: #f6f7f8;
    border-radius: 6px;
    box-shadow: 1px 1px 10px 2px #ddd;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 1rem;

    @media (max-width: 768px) {
        margin: 0 0.5rem 1rem;
    }
`

export const Title = styled.h3`
    font-size: 1.125rem;
    font-weight: normal;
    margin: 0.625rem 0 0;
    text-align: center;
`

export const Subtitle = styled.h3`
    font-size: 1.125rem;
    font-weight: normal;
    margin: 0.625rem 0 0;
    text-align: center;
`
