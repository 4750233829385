import React from 'react'
import { Container } from './styles'

const today = new Date()

const Footer = () =>
  <Container>
    Copyright © 2012 - {today.getFullYear()}
    <br />
    Centrum Medyczne Okomed S.C.
    <br />
    ul. Budowniczych LGOM 61B-C, Lubin
  </Container>

export default Footer
