import React from 'react'
import { Helmet } from 'react-helmet'
import Banner from '../Banner'
import Card from '../Card'
import { CardContainer } from './styles'

export default () =>
  <div data-testid='dermatologia'>
    <Helmet>
      <title>Dermatologia - lek. med. Grażyna Mąkosa</title>
      <meta name='description' content='Prywatny Gabinet Lekarski lek. med. Grażyna Mąkosa - specjalista chorób skóry i wenerologii. Gabinet nr 5' />
    </Helmet>
    <Banner
      title='Prywatny Gabinet Lekarski'
      subtitle='lek. med. Grażyna Mąkosa - specjalista chorób skóry i wenerologii'
    />
    <CardContainer>
      <Card title='Oferta'>
        Dermatologia estetyczna
        <ul>
          <li>PEELINGI
            <ul>
              <li>Peelingi to zabiegi polegające na złuszczaniu naskórka. W zależności od użytego
                  preparatu i potrzeb pacjenta, mogą być to peelingi powierzchowne, średnio głębokie i
                  głębokie. Poprzez zastosowanie odpowiedniego preparatu możemy osiągnąć spłycenie
                  drobnych zmarszczek, wygładzenie skóry, poprawę jej kondycji, usunięcie przebarwień i
                  drobnych blizn np. potrądzikowych.
              </li>
            </ul>
          </li>
          <li>USUWANIE PRZEBARWIEŃ
            <ul>
              <li>W celu usunięcia przebarwień najlepiej sprawdza się zabieg preparatem Cosmelan.
                  Można też stosować serię niektórych peelingów powierzchownych lub głębszych peelingów TCA.
              </li>
            </ul>
          </li>
          <li>KRIOTERAPIA <ul>
            <li>Krioterapia, czyli tak zwane wymrażanie – jest to zastosowanie bardzo niskiej temperatury do usuwania różnych znamion skórnych. Do tego celu używa się urządzeń zwanych krioterami wykorzystujących ciekły azot.</li>
            <li>Można w ten sposób usuwać:
              <ul>
                <li>brodawki wirusowe („kurzajki”)</li>
                <li>inne zmiany wirusowe</li>
                <li>włókniaki</li>
                <li>odciski</li>
                <li>brodawki łojotokowe</li>
                <li>drobne naczyniaki</li>
                <li>inne drobne niedoskonałości skóry</li>
              </ul>
            </li>
          </ul>
          </li>
          <li>Diagnostyka i leczenie chorób skóry, włosów i paznokci. Między innymi leczenie: <ul>
            <li>łuszczycy</li>
            <li>trądziku</li>
            <li>egzemy</li>
            <li>alergicznych chorób skóry</li>
            <li>grzybicy</li>
            <li>dermatoskopia (ocena) zmian barwnikowych tzw. pieprzyków</li>
          </ul>
          </li>
        </ul>
      </Card>
      <Card title='Kontakt'>
        <ul>
          <li>Gabinet nr 5, II piętro</li>
          <li>ul. Budowniczych LGOM 61B/5</li>
          <li>59-300 Lubin</li>
          <li>Tel. 607219566</li>
        </ul>
      </Card>
    </CardContainer>
  </div>
