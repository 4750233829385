import React from 'react'
import { Helmet } from 'react-helmet'

import Banner from '../Banner'
import Card from '../Card'
import { ImageBar, CardContainer } from './styles'

export default () =>
  <div data-testid='optyk'>
    <Helmet>
      <title>CM Okomed - Optyk</title>
      <meta name='description' content='Zakład Optyczny "Twój Optyk". Optyk - Ewelina Niemczuk' />
    </Helmet>
    <Banner
      title='Zakład Optyczny "Twój Optyk" s.j.'
      subtitle='Optyk - Ewelina Niemczuk'
    >
      <ImageBar>
        <img height={131} src='/images/IMG_1034-small.jpg' />
        <img height={131} src='/images/IMG_1036-small.jpg' />
        <img height={131} src='/images/IMG_1030-small.jpg' />
      </ImageBar>
    </Banner>
    <CardContainer>
      <Card title='Oferta'>
        <ul>
          <li >Kompleksowość usług ( badanie okulistyczne, fachowy dobór opraw i profesjonalne wykonanie)</li>
          <li >Ekspresowe działanie - okulary w 15 minut</li>
          <li >Szeroka gama opraw- markowe firmy GUESS, JOOP, Gianfranco Ferre, HARLEY DAVIDSON, ANNA SUI, YOUS, VANNI, EVA MINGE i inne</li>
          <li >Wysokiej jakości szkła korekcyjne- HOYA, ESSILOR</li>
          <li >Soczewki kontaktowe, płyny</li>
          <li >Okulary przeciwsłoneczne</li>
          <li >Akcesoria optyczne</li>
        </ul>
      </Card>
      <Card title='Kontakt'>
        <ul>
          <li>ul. Budowniczych LGOM 61B</li>
          <li>59-300 Lubin</li>
          <li>Czynne: poniedziałek – piątek, godz. 9-17</li>
          <li>Tel. 726 777776</li>
        </ul>
      </Card>
    </CardContainer>
  </div>
