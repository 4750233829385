import React from 'react'
import { string, node } from 'prop-types'
import { Container, Title, Subtitle } from './styles'

const Banner = ({ title, subtitle, children }) =>
  <Container>
    <Title>
      {title}
    </Title>
    {children}
    <Subtitle>
      {subtitle}
    </Subtitle>
  </Container>

Banner.propTypes = {
  children: node,
  subtitle: string,
  title: string.isRequired
}
export default Banner
