import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Menu, MenuItem, Submenu, SubmenuItem, StyledBurger } from './styles'

const NavBar = () => {
  const [open, setOpen] = useState(false)

  const closeMenu = () => {
    setOpen(false)
  }

  return (
    <Container data-testid='navbar'>
      <StyledBurger
        data-testid='burger-menu'
        open={open}
        onClick={() => setOpen(!open)}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <Menu
        data-testid='navbar-menu'
        open={open}
      >
        <MenuItem>
          <NavLink exact to='/' onClick={closeMenu}>Strona główna</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to='/oferta' onClick={closeMenu}>Oferta</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to='/okulistyka' onClick={closeMenu}>Okulistyka</NavLink>
          <Submenu>
            <SubmenuItem>
              <NavLink to='/okulistyka/stangret' onClick={closeMenu}>lek. med. Małgorzata Stangret</NavLink>
            </SubmenuItem>
            <SubmenuItem>
              <NavLink to='/okulistyka/wojtusciszyn' onClick={closeMenu}>lek. med. Henryk Wojtuściszyn</NavLink>
            </SubmenuItem>
          </Submenu>
        </MenuItem>
        <MenuItem>
          <NavLink to='/dermatologia' onClick={closeMenu}>Dermatologia</NavLink>
        </MenuItem>        
        <MenuItem>
          <NavLink to='/podologia' onClick={closeMenu}>Podologia</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to='/optyk' onClick={closeMenu}>Optyk</NavLink>
        </MenuItem>
        <MenuItem>
          <NavLink to='/kontakt' onClick={closeMenu}>Kontakt</NavLink>
        </MenuItem>
      </Menu>
    </Container>
  )
}

export default NavBar
