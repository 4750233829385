import React from 'react'
import { Helmet } from 'react-helmet'
import Card from '../Card'
import { OfertaCardContainer, KontaktDetails } from './styles'

export default () =>
  <div data-testid='kontakt'>
    <Helmet>
      <title>CM Okomed - Kontakt</title>
      <meta name='description' content='Centrum Medyczne Okomed S.C.
ul. Budowniczych LGOM 61B-C, Lubin
rejestracja w godz. 7:30 - 18
tel. 76 744 18 92' />
    </Helmet>
    <OfertaCardContainer>
      <Card>
        <KontaktDetails>
          <p>
              Centrum Medyczne Okomed S.C.<br />
              ul. Budowniczych LGOM 61B-C, Lubin
          </p>
          <p>
            <u>Rejestracja</u><br />
              Okulista: tel. 76 744 18 92<br />
              Dermatolog: tel. 607 219 566
          </p>
        </KontaktDetails>
      </Card>
      <Card>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1244.4130477337367!2d16.1755836583535!3d51.40624999490461!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470f6f567c473319%3A0x16b7e6402d398fa0!2sBudowniczych%20LGOM%2061B%2C%2059-300%20Lubin%2C%20Polska!5e0!3m2!1spl!2sus!4v1588970580924!5m2!1spl!2sus'
          width='100%'
          height='450'
          frameBorder='0'
        />
      </Card>
    </OfertaCardContainer >
  </div>
